import React, { useState } from "react";
import { connect } from "react-redux";

import { lingua_it } from "../i18n/it-IT";
import { lingua_en } from "../i18n/en-GB";
import { lingua_es } from "../i18n/es-ES";
import { lingua_ca } from "../i18n/es-CA";

import {
  IoLogoWhatsapp,
  IoIosPin,
  IoIosPhonePortrait,
  IoLogoTwitter,
  IoLogoFacebook,
} from "react-icons/io";

import { owner } from "../data";

const Contact = (props) => {
  const { lang } = props;

  let lingua = {};

  switch (lang) {
    case "it-IT":
      lingua = lingua_it;
      break;
    case "es-CA":
      lingua = lingua_ca;
      break;
    case "es-ES":
      lingua = lingua_es;
      break;
    default:
      lingua = lingua_en;
  }

  const [name, setName] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  return (
    <>
      <section className="section" style={{ background: "#f0f5f6" }}>
        <div className="container">
          <h1 className="title is-size-3-mobile is-2 is-spaced">
            {lingua.contact.titolo}
          </h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="content">
            <div className="columns">
              <div className="column is-6">
                <p className="subtitle" style={{ marginBottom: "2rem" }}>
                  {lingua.contact.sottotitolo}
                </p>
                <div>
                  <div className="media">
                    <div className="media-left">
                      <IoIosPin style={{ fontSize: "24px" }} />
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p>
                          {owner.indirizzo} - {owner.cap} {owner.citta}{" "}
                          {owner.provincia}
                        </p>
                      </div>
                    </div>
                  </div>

                  {owner.social.facebook !== "" && (
                    <div className="media">
                      <div className="media-left">
                        <IoLogoFacebook style={{ fontSize: "24px" }} />
                      </div>
                      <div className="media-content">
                        <div className="content">
                          <a href={owner.social.facebook}>Facebook</a>
                        </div>
                      </div>
                    </div>
                  )}
                  {owner.social.whatsapp !== "" && (
                    <div className="media">
                      <div className="media-left">
                        <IoLogoWhatsapp style={{ fontSize: "24px" }} />
                      </div>
                      <div className="media-content">
                        <div className="content">
                          <a href={owner.social.whatsapp}>Whatsapp</a>
                        </div>
                      </div>
                    </div>
                  )}
                  {owner.social.twitter !== "" && (
                    <div className="media">
                      <div className="media-left">
                        <IoLogoTwitter style={{ fontSize: "24px" }} />
                      </div>
                      <div className="media-content">
                        <div className="content">
                          <a href={owner.social.twitter}>Ttwitter</a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="column is-5 is-offset-1">
                <form
                  method="post"
                  action="https://formspree.io/luigi.defrancesco@globalapulia.com"
                >
                  <input type="hidden" name="bot-field" />
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-radiusless"
                        id="lsNameInput"
                        type="text"
                        placeholder={lingua.contact.nome}
                        name="nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-radiusless"
                        id="lsTelInput"
                        type="text"
                        placeholder={lingua.contact.telefono}
                        name="telefono"
                        value={telefono}
                        onChange={(e) => setTelefono(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-radiusless"
                        id="lsEmailInput"
                        type="email"
                        placeholder={lingua.contact.email}
                        name="_replyto"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <textarea
                        className="textarea is-radiusless"
                        rows="5"
                        placeholder={lingua.contact.messaggio}
                        name="messaggio"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button
                        className="button is-primary is-fullwidth is-radiusless"
                        type="submit"
                      >
                        {lingua.contact.invia}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  };
}

export default connect(mapStateToProps)(Contact);
